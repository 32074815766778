import React, {
  Component
} from 'react';
import logo from './logo.png';
import partof from './partof.png';
import './App.css';
import {
  Collapse
} from 'react-collapse';
import * as Sentry from '@sentry/browser';

class App extends Component {
  state = {
    location: null,
    fullQuery: null,
    diagCollapsed: true,
    reportCollapsed: true,
    blockType: null,
    blockedTypeMessage: null,
    blockedExplanationMessage: null,
    clientIP: null,
    orgId: '2546140',
    originId: '217033224',
    server: 'cckubernetes',
    formName: '',
    formEmail: '',
    formMessage: '',
    messageSent: false,
  }

  sendEmail = () => {
    const msg = {
      to: 'scraig@chathamcounty.org',
      from: 'webmaster@chathamcounty.org',
      subject: 'Blocked Site Report Form',
      html: '<p>A blocked site report form was submitted at ' + Date() + '</p>' +
        '<p><strong>Name: </strong>' + this.state.formName + '<br/>' +
        '<strong>Email: </strong>' + this.state.formEmail + '<br/>' +
        '<strong>Message: </strong>' + this.state.formMessage + '</p>' +
        '<p><strong>Diagnostic Information:</strong></p>' +
        '<p><strong>Block Type:</strong> ' + this.state.blockType + '<br/>' +
        '<strong>Host:</strong> ' + window.location.origin.substring(7, window.location.origin.length) + '<br/>' +
        '<strong>IP Address:</strong> ' + this.state.clientIP + '<br/>' +
        '<strong>Org ID:</strong> ' + this.state.orgId + '<br/>' +
        '<strong>Origin ID:</strong> ' + this.state.originId + '<br/>' +
        '<strong>Query:</strong> ' + this.state.fullQuery + '<br/>' +
        '<strong>Server:</strong> ' + this.state.server + '</p>',
    };
    fetch('/api/SendMail', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(msg)
    });
    this.setState({
      messageSent: true
    });
  }

  rot13 = (str) => {
    return str.replace(/[a-zA-Z]/g, function (c) {
      return String.fromCharCode((c <= 'Z' ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26);
    });
  }

  uncollapseDiag = () => {
    this.setState({
      diagCollapsed: !this.state.diagCollapsed
    });
  }

  uncollapseReport = () => {
    this.setState({
      reportCollapsed: !this.state.reportCollapsed
    });
  }

  handleNameChange = (event) => {
    this.setState({
      formName: event.target.value
    });
  }

  handleEmailChange = (event) => {
    this.setState({
      formEmail: event.target.value
    });
  }

  handleMessageChange = (event) => {
    this.setState({
      formMessage: event.target.value
    });
  }

  componentDidMount() {
      Sentry.init({ dsn: 'https://6590e28c423d45c39bfc0696c69a5945@o1065442.ingest.sentry.io/6735126'});
    var urlParams = new URLSearchParams(window.location.search);
    var urlDecode = this.rot13(urlParams.get('url'));
    urlDecode = urlDecode.substring(7, urlDecode.length);
    switch (urlParams.get('type')) {
      case 'phish':
        this.setState({
          blockedTypeMessage: 'phishing',
          blockedExplanationMessage: 'Phishing is a fraudulent attempt to get you to provide personal information under false pretenses.'
        });
        Sentry.configureScope((scope) => {
          scope.setLevel('error');
        });
        break;
      case 'security':
        this.setState({
          blockedTypeMessage: 'security',
          blockedExplanationMessage: 'To ensure network security, this resource has been blocked.'
        });
        Sentry.configureScope((scope) => {
          scope.setLevel('error');
        });
        break;
      case 'aup':
        this.setState({
          blockedTypeMessage: 'content',
          blockedExplanationMessage: 'This type of content was blocked due to policies set by Chatham County or your department.'
        });
        Sentry.configureScope((scope) => {
          scope.setLevel('info');
        });
        break;
      case 'domainlist':
        this.setState({
          blockedTypeMessage: 'domain',
          blockedExplanationMessage: 'This domain was blocked due to policies set by Chatham County or your department.'
        });
        Sentry.configureScope((scope) => {
          scope.setLevel('info');
        });
        break;
      default:
        this.setState({
          blockedTypeMessage: 'unknown',
          blockedExplanationMessage: 'The reason for this site block is unknown.'
        });
        Sentry.configureScope((scope) => {
          scope.setLevel('warning');
        });
        break;
    }
    Sentry.configureScope((scope) => {
      scope.setExtra("queryString", urlParams.toString());
    });
    Sentry.configureScope((scope) => {
      scope.setExtra("alertType", urlParams.get('type'));
    });
    Sentry.captureException("Blocked Page Triggered");
    this.setState({
      location: urlDecode,
      clientIP: urlParams.get('client_ip'),
      blockType: urlParams.get('type'),
      fullQuery: urlParams.toString()
    });
  }

  render() {
    return (
      <div className="App">
        <div className="background"></div>

        <div className="header-body">
          <div className="flex-item centerAlign"><img alt="Chatham County Logo" width="380" height="60" src={logo} style={{top: 0, width: 380, height: 60}} /></div>
          <div className="flex-item centerAlign"><a href="https://www.chathamcountyga.gov/"><img src={partof} alt="Part of Chatham County, GA" />  Part of ChathamCountyGA.gov</a></div>
        </div>

        <div className="container">
          <div className="row">
            <div className="modalbox error col-sm-8 col-md-6 col-lg-5 center animate">
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>
              </div>
              <h1>Site Blocked</h1>
              <div className="blocked-page">
                <h3>
                  <i className="fa fa-exclamation-triangle alert-icon"></i>
                  This site is blocked due to a {this.state.blockedTypeMessage} threat.
                </h3>
                <div className="url-area">
                  {this.state.location}
                </div>
              </div>
                <p> {this.state.blockedExplanationMessage}</p>
                <p> Sorry, {this.state.location} has been blocked by your network administrator.</p>
                <div>
                  <button className="link-button" onClick={this.uncollapseReport}>Report an incorrect block</button>
                    <Collapse isOpened={!this.state.reportCollapsed}>
                      <div className="infoSpace">
                      <div hidden={this.state.messageSent}>
                        <table className="contact-form-table" cellSpacing="0" cellPadding="0">
                          <tbody>
                            <tr>
                              <td className="field-name" width="30%">
                                  Your name:
                              </td>
                              <td>
                                  <input type="text" size="40" className="cancel_enter" value={this.state.formName} onChange={this.handleNameChange} />
                              </td>
                          </tr>
                          <tr>
                              <td className="field-name">
                                  Your email:
                              </td>
                              <td>
                                  <input type="email" size="40" className="cancel_enter" value={this.state.formEmail} onChange={this.handleEmailChange} />
                              </td>
                          </tr>
                          <tr>
                              <td className="field-name">
                                  Message:
                              </td>
                              <td>
                                  <textarea cols="40" rows="4" className="admin-msg" value={this.state.formMessage} onChange={this.handleMessageChange}></textarea>
                              </td>
                          </tr>
                          <tr>
                              <td>&nbsp;</td>
                              <td>
                                <button className="send-msg-btn" onClick={this.sendEmail}>Send Message</button>
                              </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div hidden={!this.state.messageSent}>
                      <h3>Message Sent</h3>
                    </div>
                      </div>
                    </Collapse>
                </div>
                <div className="mt">
                <button className="link-button" onClick={this.uncollapseDiag}>Diagnostic Info</button>
                    <Collapse isOpened={!this.state.diagCollapsed}>
                      <div className="infoSpace">
                        <div className="diagnostic-info">
                          <dl>

                            <dt>Block Type</dt>
                            <dd>{this.state.blockType}</dd>

                            <dt>Domain Tagging</dt>
                            <dd>-</dd>

                            <dt>Host</dt>
                            <dd>{window.location.origin.substring(7,window.location.origin.length)}</dd>

                            <dt>IP Address</dt>
                            <dd>{this.state.clientIP}</dd>

                            <dt>Org ID</dt>
                            <dd>{this.state.orgId}</dd>

                            <dt>Origin ID</dt>
                            <dd>{this.state.originId}</dd>

                            <dt>Query</dt>
                            <dd>{this.state.fullQuery}</dd>

                            <dt>Server</dt>
                            <dd>{this.state.server}</dd>

                            <dt>Time</dt>
                            <dd>{Date()}</dd>

                          </dl>
                        </div>
                      </div>
                    </Collapse>
                </div>
            </div>
          </div>
      </div>
      </div>
    );
  }
}

export default App;
